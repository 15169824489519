.teamDetailContentDiv {
    display: flex;
    padding: 20px 0;
    color: #FFF;
    flex-wrap: wrap;
    justify-content: center;
}

.teamDetailRosterSection,
.teamDetailMeetsSection {
    width: 600px;
    max-width: 50%;
    min-width: 300px;
    padding: 0 20px;
}

.teamDetailRoster {
    width: 90%;
    margin: 20px auto 0 auto;
    max-width: 500px;
    max-height: 600px;
}

.teamDetailMeetsList {
    flex: 1 1 100%;
    max-height: 600px;
    height: 100%;
    overflow-y: auto;
}

@media only screen and (max-width: 600px) {
    .teamDetailRosterSection,
    .teamDetailMeetsSection {
        width: 100%;
        max-width: 100%;
        margin: 30px 20px 20px 20px;
        padding: 0;
    }
    .teamDetailRoster {
        width: 100%;
    }

    .teamDetailMeets {
        min-height: initial;
        max-height: 600px;
        height: 100%;
    }
}

.teamDetailRosterMember {
    width: 100%;
    padding: 10px;
    border-top: 1px solid;
    border-width: 1px;
    border-image-slice: 1;
    border-image-source: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    background-color: rgba(0, 0, 0, 0.95);
    text-align: left;
}

.teamDetailNoRosterYet {
    background-color: rgba(0, 0, 0, 0.95);
    padding: 10px;
}

.teamDetailRosterMemberName {
    margin-right: auto;
}

.teamDetailRosterMemberRole {
    width: 50px;
    padding: 0 5px;
    text-align: center;
    display: inline-block;
}

.teamDetailRosterMemberPower {
    float: right;
    margin-right: 10px;
}

.teamDetailRosterMemberRecord {
    float: right;
}

.teamDetailMeets {
    width: 100%;
}

.teamDualMeetListA {
    text-decoration: none;
}

.teamDualMeetListDiv {
    width: 100%;
    max-width: 500px;
    min-width: 400px;
    border: 3px solid white;
    border-radius: 20px;
    padding: 10px;
    display: block;
    color: white;
    text-decoration: none !important;
    margin: 0 auto 20px auto;
}

.teamDualMeetListDiv h4 {
    font-weight: bold;
}
