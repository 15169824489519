.dualMeetCreationPage {
    color: #222;
    text-align: left;
    overflow-y: auto;
}

.dualMeetCreationBox {
    width: 90%;
    margin: auto;
}

.createNewDualMeetHeader {
    text-align: center;
    color: #fff;
    font-size: 3em;
    width: 90%;
    margin: auto;
}

.dualMeetFormBox {
    min-width: 300px;
    width: 400px;
    padding: 20px;
    border-right: 1px solid black;
    height: 100%;
    flex-shrink: 0.5;
}

.dualMeetBoxData {
    max-width: 1000px;
    margin: auto;
    background-color: #FFF;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    height: 480px;
    align-items: center;
}

.dualMeetBoxData > img {
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    object-fit: cover;
    width: 100%;
}

.dualMeetJV {
    margin: 10px 0;
    display: flex;
    align-items: end;
}

.dualMeetCreationNoMargin {
    margin: 5px 5px 5px 0 !important;
}
