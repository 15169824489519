.managerTag {
    width: 25px;
    margin-right: 3px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
}

.deleteIconRoster {
    cursor: pointer;
}
