.teamListContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0 50px;
    justify-content: space-between;
}

.teamListA {
    flex: 0 0 400px;
    text-decoration: none;
}

@media only screen and (max-width: 500px) {
    .teamListContainer {
        padding: 0;
        flex-direction: column;
        align-items: center;
    }

    .teamListA {
        width: 90%;
        flex: none;
    }
}

.teamListDiv {
    border: 3px solid white;
    border-radius: 20px;
    padding: 10px;
    display: block;
    color: white;
    text-decoration: none !important;
    margin: 20px;
}

.teamListDiv h4 {
    font-weight: bold;
}
