.dualMeetsScorerPage {
    overflow-y: auto;
}

.dualMeetScorerContainer {
    max-width: 800px;
    width: 90%;
    margin: 10px auto 20px auto;
    flex-shrink: 0;

    --button-color-inactive: #BBB;
    --button-color-hover: #AAA;
    --button-color-active: #999;
}

.dualMeetScorerBoutNav {
    width: 30px;
    height: 30px;
    padding: 10px;
    margin: 0 5px;
    background-color: #BBB;
    border: 2px solid #999;
    border-radius: 7px;
    position: relative;
    transition: 0.2s background-color;
    cursor: pointer;
}

.dualMeetScorerBoutNav.disabled {
    background-color: #999;
    border-color: #777;
    cursor: not-allowed;
}

.dualMeetScorerBoutNav:hover {
    background-color: #CCC;
}

.dualMeetScorerBoutNav.disabled:hover {
    background-color: #999;
}

.dualMeetScorerBoutNavIcon {
    position: absolute;
    top: 3px;
    left: 3px;
}
