.publicationRequests {
    margin: auto;
    width: 90%;
    max-width: 700px;
}

.publicationRequest {
    width: 100%;
    margin-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    padding-bottom: 10px;
    text-align: left;
}

.publicationRequest p {
    padding: 0 5px;
    color: white;
    font-size: 16px;
    margin: 0;
}

.publicationRequestHeader {
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    color: white;
    padding: 10px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.publicationRequestContent {
    padding: 0 10px;
}
