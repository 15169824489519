.aboutPage {
    color: #CCC;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 10px 0 10px;
}

.aboutPage h2 {
    font-weight: bold;
    font-size: 48px;
    margin-bottom: 10px;
}

.aboutPage h3 {
    font-size: 24px;
    font-weight: 600;
}

.aboutPage h4 {
    font-size: 18px;
}

.aboutPage h5 {
    font-size: 16px;
}

.aboutPage img {
    width: 50px;
    height: 50px;
}

.stockNotice {
    font-size: 10px;
    color: #888;
}

.aboutInfo {
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    max-width: 600px;
    width: 90vw;
    margin: 0 auto 20px auto;
    padding-bottom: 1px;
    text-align: center;
}

.aboutInfoHeader {
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .aboutInfoHeader h2 {
        font-size: 30px;
    }
}

.aboutLowerSectionV2 {
    text-align: center;
}

.aboutLowerSectionV2 img {
    height: 100px;
    width: 100%;
    object-fit: contain;
}

.appDownloadsV2 {
    max-width: 600px;
    width: 90vw;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
}

.appDownloadsV2 a {
    height: 100%;
}

.appDownloadsV2 img {
    width: auto;
    height: 100%;
    object-fit: fill;
}

.userGuidesV2 {
    max-width: 600px;
    width: 90vw;
    display: flex;
    justify-content: space-around;
    margin: 25px auto 0 auto;
    flex-wrap: wrap;
}

/* .userGuidesV2 a {
    width: 40%;
    min-width: 200px;
    margin-bottom: 10px;
    height: 120px;
    text-decoration: none;
}

.userGuide {
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    color: white;
    padding: 10px;
    font-size: 24px;
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
} */

.userGuidesV2 a {
    width: 425px;
    min-width: 200px;
    margin-bottom: 10px;
    height: 120px;
    text-decoration: none;
}

.userGuide {
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    color: white;
    padding: 10px;
    font-size: 24px;
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}
