.organizationDetailDiv {
    width: 90%;
    max-width: 500px;
    margin: 0 auto 30px auto;
}

.configRow {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.organizationDetailPage {
    padding-bottom: 1px;
}
