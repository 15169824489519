.orgCreationBox {
    width: 90%;
    margin: 0 auto 20px auto;
}

.createNewOrgHeader {
    text-align: center;
    color: #fff;
    margin: 0 auto 20px auto;
    font-size: 3em;
    width: 90%;
}

.orgCreationPage {
    color: #222;
    text-align: left;
    overflow-y: auto;
}

.orgCreationTeamCreation {
    display: flex;
    align-items: end;
}

.orgCreationTeamCreation label {
    margin: 6px 10px 4px 0 !important;
}
