.boutPlayer {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    border: 2px solid #666;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
}

.boutPlayer h3,
.boutPlayer h5 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.currentBoutScore {
    width: 100%;
    height: calc(100% - 100px);
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    border: 1px solid red;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.currentBoutScorePriority {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 24px;
    font-family: "Lexend Deca";
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}