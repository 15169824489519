

.boutLogMobile {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.boutLog {
    width: 34%;
    height: 244px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.boutEvent {
    font-size: 12px;
    padding: 15px 0;
    position: relative;
}

.boutEvent:first-child {
    margin-top: 15px;
}

.boutEvent:not(:last-child):before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 25px);
    width: 50px;
    height: 1px;
    border-top: 1px solid white;
}