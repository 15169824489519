.eventCreationPage {
    color: #222;
    text-align: left;
    overflow-y: auto;
}

.meetListText {
    user-select: none;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
}

.stepBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 500px;
}

.eventDetailsInput {
    width: 400px;
    margin-bottom: 15px !important;
}

.eventCreationWriteInCheckbox {
    display: flex;
    align-items: center;
}

.meetInRound {
    margin: 5px 10px 5px 0;
    padding: 0;
    display: flex;
    align-items: center;
    transition: 0.3 ease border-color;
}
