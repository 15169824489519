.dualMeetNameLink {
    color: black;
    text-decoration: none;
}

.dualMeetNameLinkDisabled {
    color: black;
    text-decoration: none;
}

.dualMeetNameLinkDisabled:hover {
    color: black;
    text-decoration: none;
}

.summaryOption {
    margin: 10px !important;
    flex-shrink: 0;
}

.summaryNameColumn {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}