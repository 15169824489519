.liveNowBout {
    background-color: rgba(8, 8, 8, 0.9);
    display: inline-block;
    margin: auto;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    max-width: 90vw;
    cursor: pointer;
}

.liveNowBoutCollege {
    background-color: rgba(225, 225, 225, 0.9);
    display: inline-block;
    margin: auto;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    max-width: 90vw;
    cursor: pointer;
}