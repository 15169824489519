.navMargin {
    margin-right: 150px;
}

@media only screen and (max-width: 760px)
{
    .navMargin {
        margin-right: initial;
    }
}
