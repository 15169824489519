.tbtPage.hs::before {
    content: "";
    position: fixed;
    left: -1%;
    right: -1%;
    top: -1%;
    bottom: -1%;
    z-index: -1;

    display: block;
    background-image: url("../../assets/FencersAngled.jpg");
    background-size: cover;
    background-position: center;
    width: 102%;
    height: 110%;
    opacity: 0.4;
}

.tbtPage.college {
    background-color: #f5f5f5;
}

.tbtPage {
    min-height: calc(100vh - 115px);
    background-size: cover;
    font-family: "Poppins", sans-serif;
    text-align: center;
}

.tbtPage.hs {
    color: #eee;
}
