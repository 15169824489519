.dualMeetScorerBox {
    background-color: rgba(0, 0, 0, 0.95);
    text-align: center;
    display: flex;
    flex-direction: column;
    color: white;
    height: 640px;
    width: 100%;
    font-size: 18;
}

.dualMeetScorerHeader {
    position: relative;
    display: flex;
    justify-content: center;
}

.dualMeetScorerTime {
    display: flex;
}

.dualMeetScorerTime h3 {
    font-size: 48px;
}

.dualMeetScorerTimeButtonBox {
    width: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.dualMeetScorerTimeButton {
    width: 26px;
    height: 22px;
    background-color: #BBB;
    text-align: center;
    transition: background-color 0.1s;
    border: 1px solid #AAA;
    position: relative;
    cursor: pointer;
}

.dualMeetScorerTimeButton:hover {
    background-color: #CCC;
}

.dualMeetScorerTimeButton:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 1px;
}

.dualMeetScorerTimeButton:last-child {
    margin-top: 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dualMeetScorerTimeButtonIcon {
    position: absolute;
    top: 4px;
    left: 6px;
}

.dualMeetScorerStartToggle,
.dualMeetScorerStartToggleMobile {
    background-color: rgb(100, 255, 160);
    border: 2px solid green;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.dualMeetScorerStartToggle {
    position: absolute;
    top: 6px;
    left: 6px;
}

.dualMeetScorerStartToggleMobile {
    margin: auto;
    width: 200px;
    height: 40px;
    line-height: 32px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.dualMeetScorerCol {
    width: 30%;
    height: 375px;
    flex-shrink: 0;
}

.dualMeetScorerBoutPlayer {
    width: calc(100% - 20px);
    height: 340px;
    margin: 10px;
    border: 2px solid #666;
    padding: 10px;
    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .dualMeetScorerCol {
        width: 50%;
        height: 280px;
    }
    .dualMeetScorerBoutPlayer {
        height: 320px;
        border: none;
        padding: 0;
        height: 300px;
    }
}

.dualMeetCurrentBoutScore {
    width: 100%;
    min-height: 100px;
    height: calc(100% - 125px);
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    user-select: none;
}

.dualMeetCurrentBoutScorePriority {
    position: absolute;
    bottom: 0px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dualMeetIncreaseCurrentBoutScore,
.dualMeetDecreaseCurrentBoutScore {
    width: 100%;
    height: 30px;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #777;
    background-color: var(--button-color-inactive);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.1s background-color;
}

.dualMeetIncreaseCurrentBoutScore {
    border-radius: 10px 10px 0 0;
    margin-bottom: 5px;
}

.dualMeetDecreaseCurrentBoutScore {
    border-radius: 0 0 10px 10px;
    margin-top: 5px;
}

.dualMeetIncreaseCurrentBoutScore:hover,
.dualMeetDecreaseCurrentBoutScore:hover {
    background-color: var(--button-color-hover);
}

.dualMeetIncreaseCurrentBoutScore:active,
.dualMeetDecreaseCurrentBoutScore:active {
    background-color: var(--button-color-active);
}

.dualMeetScorerFencerSplitActionContainer {
    height: 70px;
    flex-grow: 0;
    flex-shrink: 0;
    width: calc(50% - 20px);
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dualMeetScorerFencerSplitAction {
    background-color: var(--button-color-inactive);
    border: 1px solid #777;
    border-radius: 5px;
    padding: 5px 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transition: 0.1s background-color;
}

.dualMeetScorerFencerActions {
    display: flex;
}

.dualMeetScorerFencerAction {
    background-color: var(--button-color-inactive);
    border: 1px solid #777;
    border-radius: 5px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 10px;
    padding: 10px 0;
    width: calc(50% - 20px);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transition: 0.1s background-color;
}

@media only screen and (max-width: 600px) {
    .dualMeetScorerFencerAction {
        margin: 0 5px;
        width: calc(50% - 10px);
    }
}

.dualMeetScorerFencerAction:hover {
    background-color: var(--button-color-hover);
}

.dualMeetScorerFencerAction:active {
    background-color: var(--button-color-active);
}

.dualMeetScorerCenterCol {
    flex-grow: 1;
    height: 440px;
}

.dualMeetScorerCenterCol h3 {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.dualMeetScorerBoutLog {
    height: 290px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

@media only screen and (max-width: 600px) {
    .dualMeetScorerCenterCol {
        height: 205px;
        flex-grow: 0;
    }

    .dualMeetScorerCenterCol .dualMeetScorerBoutLog {
        height: calc(100% - 50px);
    }
}

.dualMeetScorerNoEvents {
    color: grey;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin: 0;
}

.dualMeetScorerBoutEvent {
    font-size: 12px;
    padding: 15px 0;
    position: relative;
}

.dualMeetScorerFlag {
    cursor: pointer;
}

.dualMeetScorerSwitchSides {
    width: 30px;
    height: 30px;
    background-color: #BBB;
    border-radius: 7px;
    border: 2px solid #AAA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.2s;
}

.dualMeetScorerTimeControllerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dualMeetScorerTimeController {
    width: 50px;
    height: 50px;
    background-color: #BBB;
    border-radius: 7px;
    border: 2px solid #CCC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.2s;
}

.dualMeetScorerRedFlagButtons {
    width: 20px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0;
}

.dualMeetScorerRedFlagButton {
    width: 20px;
    height: 15px;
    background-color: #BBB;
    text-align: center;
    transition: background-color 0.1s;
    border: 1px solid #AAA;
    position: relative;
    cursor: pointer;
}

.dualMeetScorerRedFlagButton:hover {
    background-color: #CCC;
}

.dualMeetScorerRedFlagButton:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 1px;
}

.dualMeetScorerRedFlagButton:last-child {
    margin-top: 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dualMeetScorerRedFlagButtonIcon {
    position: absolute;
    top: 1px;
    left: 3px;
}
