.tbtTab {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    color: #444;
}

.tbtTabContent {
    flex-shrink: 0;
    width: 100%;
}

.tbtTabView {
    width: 100%;
    margin: 10px auto;
    overflow-x: hidden;
}

.tbtTabViewRow {
    display: flex;
    width: fit-content;
    max-width: 90vw;
    margin: auto;
    justify-content: left;
    border-radius: 10px;
    background-color: white;
    overflow-x: auto;
}

.tbtTabViewTab {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: white;
    color: #212529;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
}

.tbtTabViewTab:hover {
    background-color: #eee;
}

.tbtTabViewTabSelected {
    background-color: #ddd;
}

.tbtTabViewContent {
    transition: transform 0.8s cubic-bezier(0.5, 0, 0.5, 1.0);
    width: 100vw;
    padding-top: 10px;
    display: flex;
    overflow-x: none;
}

.tbtTabDisabled {
    cursor: not-allowed;
}

.tbtTabViewTab:hover {
    background-color: inherit;
}
