.dualMeetListInfoLink {
    text-decoration: none;
}

.dualMeetListInfoLink:hover {
    text-decoration: none;
}

.dualMeetListInfo {
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    max-width: 600px;
    width: 90vw;
    margin: 0 auto 20px auto;
    padding-bottom: 1px;
    text-align: center;
}

.dualMeetListInfo > p {
    padding: 0 5px;
    color: white;
    font-size: 18px;
    margin: 0;
}

.dualMeetListInfoHeader {
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    color: white;
    padding: 10px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dualMeetListInfoJVFlag {
    width: 90%;
    margin: auto;
    height: 40px;
    background-color: #222;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-style: italic;
    color: white;
    text-decoration: initial;
}

.dualMeetListsPage {
    padding-top: 30px;
}
