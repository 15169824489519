.landingPage {
    padding-top: 30px;
}

.landingTable {
    max-width: 90vw;
    width: 700px;
    margin: auto;
}

.landingPageText {
    color: white;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 25px;
    display: inline-block;
    text-align: center;
}

.landingPageNotice {
    max-width: 700px;
    width: 90%;
    border: 3px solid white;
    border-radius: 10px;
    background-color: rgba(186, 124, 166, 0.8);
    margin: 0 auto 20px auto;
    padding: 10px 20px 5px 20px;
}

.tournamentInfo {
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    max-width: 600px;
    width: 90vw;
    margin: 0 auto 20px auto;
    padding-bottom: 1px;
    text-align: center;
}

.tournamentInfo > p {
    padding: 0 5px;
    color: white;
    font-size: 18px;
    margin: 0;
}

.tournamentInfoHeader {
    background: linear-gradient(
        270deg,
        #000aff -20%,
        #e19494 120%
    );
    color: white;
    padding: 10px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
